import React from 'react';

function ErrorPage() {
  return (
    <div>
      <h1>Erreur : Votre action n&apos;a pas pu être prise en compte</h1>
      <h2>
        Veuillez renseigner de nouveau votre choix depuis le mail
        d&apos;invitation
      </h2>
    </div>
  );
}

export default ErrorPage;
